<template>
  <v-main v-if="loadingComponent">
    <v-dialog v-model="dialog" persistent size="xl" transition="top">
      <template v-slot:activator="{ on, attr }">
        <v-row>
          <v-col cols="12">
            <v-btn
              v-if="isRequiredChecklist(richiestaIntegrazione)"
              color="primary"
              v-on="on"
              v-bind="attr"
              @click="dialog = true"
            >
              Compila checklist
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-tooltip v-if="!isFileDownloading" top>
              <template v-slot:activator="{ on, attrs }">
                <a
                  v-on="on"
                  v-bind="attrs"
                  @click="downloadTemplatePdf(richiestaIntegrazione.id)"
                >
                  Download template allegato <v-icon>mdi-paperclip</v-icon>
                </a>
              </template>
              <span>
                Il template PDF è disponibile solo quando è richiesta la
                compilazione delle checklist integrative.</span
              >
            </v-tooltip>
            <v-progress-circular v-else indeterminate color="blue" />
          </v-col>
        </v-row>
      </template>

      <v-card v-if="dialog">
        <v-card-title>Compilazione checklist di integrazione</v-card-title>
        <v-card-text>
          <v-form ref="formChecklistIntegrative" lazy-validation>
            <v-expansion-panels accordion v-model="panel">
              <v-expansion-panel
                v-for="(checklist, index) in formChecklistIntegrative"
                :key="index"
              >
                <v-expansion-panel-header class="align-center h-75">
                  <v-row>
                    <v-col class="align-start" cols="8">
                      <h3 class="primary--text">
                        {{ checklist.tipologiaChecklist.descrizione }}
                      </h3>
                    </v-col>
                    <v-col cols="2">
                      <h3 class="align-end">
                        <v-chip
                          outlined
                          pill
                          :color="
                            getStatoCompilazioneChecklist(
                              checklist.risposteChecklist
                            ).color
                          "
                          v-html="
                            getStatoCompilazioneChecklist(
                              checklist.risposteChecklist
                            ).text
                          "
                          class="primary--text"
                        />
                      </h3>
                    </v-col>
                  </v-row>
                </v-expansion-panel-header>
                <v-expansion-panel-content v-if="loadingForm">
                  <v-main
                    v-for="(
                      formChecklist, numeroDomanda
                    ) in checklist.risposteChecklist"
                    :key="numeroDomanda"
                  >
                    <p
                      v-html="
                        getTestoDomanda(
                          checklist.tipologiaChecklist.id,
                          numeroDomanda
                        )
                      "
                    />
                    <v-row>
                      <v-col cols="3">
                        <v-select
                          :disabled="
                            isDisabledFormChecklist(
                              checklist.tipologiaChecklist.id,
                              numeroDomanda + 1
                            )
                          "
                          :items="itemsSelect"
                          item-text="text"
                          item-value="value"
                          v-model="formChecklist.conferma"
                          :rules="[
                            (v) =>
                              v !== -1 ||
                              'Bisogna selezionare una delle opzioni',
                          ]"
                          single-line
                          label="Note"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-textarea
                          v-model="formChecklist.descrizioneConferma"
                          outlined
                          readonly
                          disabled
                          label="Elenco dei documenti a comprova"
                        />
                      </v-col>
                      <v-col cols="3">
                        <v-textarea
                          v-model="formChecklist.note"
                          label="Note"
                          outlined
                          :disabled="
                            isDisabledFormChecklist(
                              checklist.tipologiaChecklist.id,
                              numeroDomanda + 1
                            )
                          "
                        />
                      </v-col>
                    </v-row>
                  </v-main>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <DialogConferma
            :dialog.sync="dialogConferma"
            @callback="submitForm()"
            @close-dialog="dialogConferma = false"
          />
          <v-snackbar
            :color="snackbar.color"
            v-model="snackbar.value"
            :timeout="2000"
          >
            {{ snackbar.text }}
          </v-snackbar>
          <v-row>
            <v-col cols="6" />
            <v-col cols="3">
              <v-btn color="warning" @click="dialog = false">
                Chiudi finestra di dialog
              </v-btn>
            </v-col>
            <v-col cols="3">
              <v-btn color="primary" outlined @click="dialogConferma = true">
                Salva checklist</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>
<script>
import fileTestiChecklist from "@/assets/ChecklistText.json";
import DialogConferma from "@/components/DialogConferma.vue";
import RichiesteIntegrazioniModificheRepository from "@/api/istituto/RichiesteIntegrazioniModificheRepository";

export default {
  name: "dialogChecklistIntegrative",
  components: { DialogConferma },
  props: {
    richiestaIntegrazione: {
      type: Object,
      required: true,
    },
    rendiconto: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loadingComponent: false,
    dialog: false,
    formChecklistIntegrative: [],
    checklistTypeRendiconti: [],
    itemsSelect: [
      { text: "Seleziona", value: -1 },
      { text: "SI", value: 1 },
      { text: "NO", value: 2 },
      { text: "NA", value: 3 },
    ],
    endApiTipoChecklist: false,
    panelChecklist: false,
    testiChecklist: null,
    loadingForm: false,
    panel: false,
    snackbar: {
      color: "",
      text: "",
      value: false,
    },
    dialogConferma: false,
    isFileDownloading: false,
  }),
  computed: {},
  watch: {},
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      if (this.richiestaIntegrazione) {
        this.setupForm();
        //this.getChecklistDaModificare();
        this.loadingComponent = true;
      }
    },
    isRequiredChecklist(richiestaIntegrazione) {
      return !!(
        richiestaIntegrazione.flCompilazioneChecklistSezioneA ||
        richiestaIntegrazione.flCompilazioneChecklistSezioneB ||
        richiestaIntegrazione.flCompilazioneChecklistSezioneC ||
        richiestaIntegrazione.flCompilazioneChecklistSezioneD ||
        richiestaIntegrazione.flCompilazioneChecklistSezioneE ||
        richiestaIntegrazione.flCompilazioneChecklistSezioneF ||
        richiestaIntegrazione.flCompilazioneChecklistSezioneG
      );
    },
    getTestoDomanda(idTipoChecklist, numeroDomanda) {
      switch (idTipoChecklist) {
        case 1:
          return fileTestiChecklist.checklistSezioneA.testi[numeroDomanda];

        case 2:
          return fileTestiChecklist.checklistSezioneB.testi;
        case 3:
          return fileTestiChecklist.checklistSezioneC.testi[numeroDomanda];

        case 4:
          return fileTestiChecklist.checklistSezioneD.testi[numeroDomanda];

        case 5:
          return fileTestiChecklist.checklistSezioneE.testi[numeroDomanda];
        case 6:
          return fileTestiChecklist.checklistSezioneF.testi;

        case 7:
          return fileTestiChecklist.checklistSezioneG.testi[numeroDomanda];
      }
    },
    getTestiPrecompilatiByTypeInfo(tipoInfo, idTipoChecklist, numeroDomanda) {
      let keyInfo =
        tipoInfo === "note"
          ? "placeholderNote"
          : "placeholderDescrizioniConferme";
      switch (idTipoChecklist) {
        case 1:
          return fileTestiChecklist.checklistSezioneA[keyInfo][numeroDomanda];

        case 2:
          return fileTestiChecklist.checklistSezioneB[keyInfo][numeroDomanda];
        case 3:
          return fileTestiChecklist.checklistSezioneC[keyInfo][numeroDomanda];

        case 4:
          return fileTestiChecklist.checklistSezioneD[keyInfo][numeroDomanda];

        case 5:
          return fileTestiChecklist.checklistSezioneE[keyInfo][numeroDomanda];
        case 6:
          return fileTestiChecklist.checklistSezioneF[keyInfo][numeroDomanda];

        case 7:
          return fileTestiChecklist.checklistSezioneG[keyInfo][numeroDomanda];
      }
    },
    setupForm() {
      let elementForm = [];
      this.richiestaIntegrazione.checklistIntegrative.map((element, index) => {
        const numeroDomande = element.tipologiaCheckList.numeroDomande;
        elementForm[index] = {
          tipologiaChecklist: element.tipologiaCheckList,
          risposteChecklist: [],
        };
        for (let i = 0; i < numeroDomande; i++) {
          let keyConferma = "conferma" + parseInt(i + 1);
          let keyNote = "note" + parseInt(i + 1);
          let keyDescrizione = "descrizioneConferma" + parseInt(i + 1);
          elementForm[index]["risposteChecklist"].push({
            conferma:
              // sezione e, domanda 2 deve essere resa readonly con valore 'Na' ( quindi valore 3) di default, qualora non è stata compilata alcuna checklist integrativa; viceversa prende già il valore NA
              element[keyConferma] != null && element[keyConferma] > 0
                ? element[keyConferma]
                : this.isDisabledFormChecklist(
                    element.tipologiaCheckList.id,
                    parseInt(i + 1)
                  )
                ? 3
                : -1,
            descrizioneConferma:
              element[keyDescrizione] ??
              this.getTestiPrecompilatiByTypeInfo(
                "descrConferme",
                element.tipologiaCheckList.id,
                i
              ),
            note:
              element[keyNote] ??
              this.getTestiPrecompilatiByTypeInfo(
                "note",
                element.tipologiaCheckList.id,
                i
              ),
          });
        }
      });
      this.formChecklistIntegrative = elementForm;
      this.loadingForm = true;
    },
    setupFormBodyRequest() {
      // formatto la richiesta in modo tale che,
      // ogni proprietà della entity della checklist integrativa  abbia già il valore associato
      let valueForm = [];
      this.formChecklistIntegrative.forEach((element) => {
        let checklistIntegrativa = {
          tipologiaCheckList: element.tipologiaChecklist,
        };

        // risposte checklist
        element.risposteChecklist.forEach((inputUser, indexInput) => {
          // ottengo le proprietà effettive della checklist di integrazione( quelle della entity sul be)
          let keyConferma = "conferma" + (parseInt(indexInput) + 1);
          let keyDescrizioneConferma =
            "descrizioneConferma" + (parseInt(indexInput) + 1);
          let keyNote = "note" + (parseInt(indexInput) + 1);
          checklistIntegrativa[keyConferma] = inputUser.conferma;
          checklistIntegrativa[keyDescrizioneConferma] =
            inputUser.descrizioneConferma;
          checklistIntegrativa[keyNote] = inputUser.note;
        });
        valueForm.push(checklistIntegrativa);
      });

      return valueForm;
    },
    async submitForm() {
      if (!this.checkAllChecklistInsert()) {
        // snackbar di errore
        this.setValueSnackbar(
          false,
          "Attenzione. E' obbigatoria la compilazione dei campi richiesti"
        );
      } else {
        let requestFormatted = JSON.stringify(this.setupFormBodyRequest());
        /*const formBody = new FormData();
        formBody.append("checklistIntegrativeRequest", requestFormatted);*/
        await RichiesteIntegrazioniModificheRepository.updateChecklistIntegrative(
          this.rendiconto.idStrutture,
          this.rendiconto.id,
          this.richiestaIntegrazione.id,
          requestFormatted
        )
          .then((data) => {
            this.$emit("afterCompilationChecklist", data);
            this.setValueSnackbar(true, "Salvataggio avvenuto con successo");
            this.dialog = false;
          })
          .catch((reason) => {
            if (reason.response.status === 500) {
              this.setValueSnackbar(
                false,
                "Attenzione. Un errore generico è stato riscontrato nella richiesta. Contattare il supporto se il problema persiste"
              );
            } else {
              this.setValueSnackbar(false, reason.response.data);
            }
          });
      }
    },
    getStatoCompilazioneChecklist(checklistSezione) {
      let resp = {
        color: "",
        state: "",
      };
      // recupero tutte le risposte fornite
      const confermeNonCompilate = checklistSezione.filter(
        (element) => element.conferma === -1
      );
      if (confermeNonCompilate.length === 0) {
        resp.color = "success";
        resp.text = "Compilato";
      } else if (confermeNonCompilate.length === checklistSezione.length) {
        // non esistono conferme com valore !== -1, quindi nessuna conferma è stata inserita
        resp.color = "red darken-2";
        resp.text = "Non Compilate";
      } else {
        resp.color = "purple-darken-1";
        resp.text = "In corso";
      }

      return resp;
    },
    checkAllChecklistInsert() {
      let check = true;
      let i = 0;
      while (i < this.formChecklistIntegrative.length) {
        let stateCompilation = this.getStatoCompilazioneChecklist(
          this.formChecklistIntegrative[i].risposteChecklist
        );
        if (stateCompilation.text !== "Compilato") {
          check = false;
          break;
        }
        i++;
      }
      return check;
    },
    setValueSnackbar(esito, text) {
      this.snackbar.color = esito ? "success" : "red accent-2";
      this.snackbar.text = text;
      this.snackbar.value = true;
    },
    async downloadTemplatePdf(idRichiesta) {
      let idStrutture = this.rendiconto.idStrutture ?? this.idStrutture;
      let idRendiconto = this.rendiconto.id;
      this.isFileDownloading = true;
      await RichiesteIntegrazioniModificheRepository.downloadTemplatePdfRichiesta(
        idStrutture,
        idRendiconto,
        idRichiesta
      );
      this.isFileDownloading = false;
    },
    isDisabledFormChecklist(idTipoChecklist, numeroDomanda) {
      // La domanda della checklist della 'sezione B' e la domanda due della sezione e devono essere disabilitare con valore pre-compilato 'na'.
      return (
        idTipoChecklist === 2 || (idTipoChecklist === 5 && numeroDomanda === 2)
      );
    },
  },
};
</script>
