import Repository from "@/api/Repository";

export default {
  async updateRichiestaIntegrazioni(
    idStrutture,
    idRendiconto,
    idRichiesta,
    data
  ) {
    const url = `api/istituto/${idStrutture}/rendiconti/${idRendiconto}/richieste-integrazioni/${idRichiesta}/trasmetti`;
    const headers = { "Content-type": "multipart/form-data" };
    return (await Repository.post(url, data, { headers })).data;
  },
  async updateChecklistIntegrative(
    idStrutture,
    idRendiconto,
    idRichiestaIntegrazione,
    formBody
  ) {
    const url = `api/istituto/${idStrutture}/rendiconti/${idRendiconto}/richieste-integrazioni/${idRichiestaIntegrazione}/checklist-integrative`;
    const headers = { "Content-type": "application/json" };
    return (await Repository.post(url, formBody, { headers })).data;
  },
  async downloadTemplatePdfRichiesta(
    idStrutture,
    idRendiconto,
    idRichiestaIntegrazione
  ) {
    const url = `api/istituto/${idStrutture}/rendiconti/${idRendiconto}/richieste-integrazioni/${idRichiestaIntegrazione}/checklist-integrative/pdf`;
    const res = await Repository.get(url, { responseType: "blob" });
    if (res.status === 200) {
      const file = new Blob([res.data], { type: res.data.type });
      const objectUrl = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = objectUrl;
      fileLink.download =
        "checklist_pdf_richiesta_integrazioni_" +
        idRichiestaIntegrazione +
        ".pdf";
      fileLink.click();
      URL.revokeObjectURL(file);
    }
  },
};
